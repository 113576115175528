import Carousel from '@react/shared/components/Carousel';

import Quote from './QuoteCard';

export type Quote = {
  quote: string;
  author: string;
  relation: string;
  avatar: string;
  stars?: number;
  date?: string;
};

const SeniorlyReviews = ({ quotes }: { quotes: Quote[] }) => {
  if (!quotes?.length) {
    return <></>;
  }

  return (
    <section className='mx-8 mb-6 space-y-6 text-center'>
      <h3 className='font-t-m mx-auto max-w-lg'>
        From assisted living to memory care and beyond - see why more families
        trust Seniorly.
      </h3>
      <Carousel
        itemsQty={quotes.length}
        className='mx-auto max-w-5xl'
        controlPosition='sides'
        eventProps={{ location: 'homepage - family reviews' }}
        offset
      >
        {quotes?.map(({ quote, author, relation, avatar, stars, date }) => (
          <Quote
            key={author + quote}
            quote={quote}
            author={author}
            relation={relation}
            avatar={avatar}
            stars={stars}
            date={date}
          />
        ))}
      </Carousel>
    </section>
  );
};

export default SeniorlyReviews;
