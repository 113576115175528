import { generateSearchUrl } from '@sly/frontend/common/helpers/url';
import SearchBoxContainer from '@sly/frontend/react/search/components/SeachBoxContainer';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

const PersonalizedSearch = () => {
  const router = useRouter();

  const handleCurrentLocation = useCallback((addresses: any) => {
    if (addresses.length) {
      const path = `${generateSearchUrl(['Senior Living'], addresses[0])}`; // ?geo=${latitude},${longitude},10`;
      router.push(path);
    }
  }, []);

  return (
    <section className='border-b px-8 py-12'>
      <div className='mx-0 flex max-w-5xl flex-col items-center justify-center rounded-xl bg-viridian-lighter-90 px-12 py-10 md:py-20 md:px-32 lg:mx-auto'>
        <section className='w-full max-w-md space-y-6 lg:max-w-none'>
          <h2 className='font-t-l text-center'>
            Find personalized senior living options in your area
          </h2>
          <SearchBoxContainer
            eventProps={{
              location: 'homepage - search box - personalized',
            }}
            onCurrentLocation={handleCurrentLocation}
            className='mx-auto max-w-lg'
            include='community'
            placeholder='Search by city, state, ZIP code, community name'
            size='lg'
          />
        </section>
      </div>
    </section>
  );
};

export default PersonalizedSearch;
